/** @format */

import React from "react";
import Link from "next/link";
import Navigation from "../components/Navigation";
import styles from "../styles/Vorlage.module.scss";
import { FaPhone, FaLinkedin, FaMapMarkerAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Container } from "@material-ui/core";
import { useRouter } from "next/router";
import Alert from "./Alert";
import Footer from "./Footer";

/* Vorlage Template für alle Seiten */
const Vorlage = ({ children }) => {
  const router = useRouter();
  if (router.route === "/meeting/[roomId]") return <div>{children}</div>;
  if (router.route.includes("/profil") || router.route === "/suche")
    return (
      <div className={styles.wrapper}>
        <header>
          <meta
            name="viewport"
            content="width=device-width, user-scalable=no"
          />
          <Navigation />
        </header>
        <main className={styles.content}>{children}</main>
      </div>
    );
  return (
    <div className={styles.wrapper}>
      <header>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <Navigation />
      </header>
      <main className={styles.content}>{children}</main>
      <Footer />
      <Alert />
    </div>
  );
};

export default Vorlage;
