/** @format */

import { useEffect } from "react";
import { useRouter } from "next/router";
import "./global.css";
import "antd/dist/antd.css";
import Vorlage from "../components/Vorlage";
import AuthProvider from "../utils/context";
import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { ConfigProvider } from "antd";
import "moment/locale/de";
import locale from "antd/lib/locale-provider/de_DE";
import "isomorphic-unfetch";
import CookieBanner from "../components/CookieBanner";
const appTheme = createMuiTheme({
  palette: {
    primary: { main: "#005074" },
    secondary: { main: "#65c3af" },
    warning: { main: "#ffa500" },
  },
});

const theme = responsiveFontSizes(appTheme);

export default function App({ Component, pageProps }) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      if (window && window._paq) {
        window._paq.push(["setCustomUrl", url]);
        window._paq.push(["setDocumentTitle", document.title]);
        window._paq.push(["trackPageView"]);
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);
  }, []);
  return (
    <AuthProvider>
      <MuiThemeProvider theme={theme}>
        <ConfigProvider locale={locale}>
          <Vorlage>
            <Component {...pageProps} />
          </Vorlage>
          <CookieBanner />
        </ConfigProvider>
      </MuiThemeProvider>
    </AuthProvider>
  );
}
