/** @format */

import React, { useRef } from "react";
import { useAuth } from "../utils/context";
import { Button } from "@material-ui/core";
export default function GetCert({ handler, download }) {
  const inputEl = useRef();
  const user = useAuth();

  /* Private Key aus lokalen Dateien importieren und in CryptoKey umwandeln */
  async function getCert(privateKey) {
    const pemString = await privateKey.text();
    function removeLines(str) {
      return str.replace("\n", "");
    }

    function base64ToArrayBuffer(b64) {
      var byteString = window.atob(b64);
      var byteArray = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
      }

      return byteArray;
    }

    function pemToArrayBuffer(pem) {
      var b64Lines = removeLines(pem);
      var b64Prefix = b64Lines.replace("-----BEGIN RSA PRIVATE KEY-----", "");
      var b64Final = b64Prefix.replace("-----END RSA PRIVATE KEY-----", "");

      return base64ToArrayBuffer(b64Final);
    }

    window.crypto.subtle
      .importKey(
        "pkcs8",
        pemToArrayBuffer(pemString),
        {
          name: "RSA-OAEP",
          hash: { name: "SHA-256" }, // or SHA-512
        },
        true,
        ["decrypt"]
      )
      .then(function (importedPrivateKey) {
        user.setPrivateKey(importedPrivateKey);

        if (handler) {
          handler();
        }
      })
      .catch(function (err) {
        user.handleAlert(
          "Entschlüsselung fehlgeschlagen. Überprüfen Sie den hocgeladenen Schlüssel",
          false
        );
      });
  }

  return (
    <div>
      {download ? (
        <Button
          variant="contained"
          onClick={() =>
            user.privateKey ? handler() : inputEl.current.click()
          }
        >
          Download
        </Button>
      ) : (
        <Button onClick={() => inputEl.current.click()}>Hochladen</Button>
      )}
      <input
        id="icon-button-file"
        type="file"
        accept=".pem"
        style={{ display: "none" }}
        onChange={(e) => getCert(e.target.files[0])}
        ref={inputEl}
      />
    </div>
  );
}
