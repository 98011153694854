import React from "react";
import styles from "../styles/Vorlage.module.scss";
import { Container } from "@material-ui/core";
import Link from "next/link";
import { FaLinkedin, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
export default function Footer() {
  return (
    <footer>
      <div className={styles.footer}>
        <Container maxWidth="md">
          <div className={styles.parent}>
            <div className={styles.div1}>
              <Link href="https://carenext.net/ueber-uns">
                <span className={styles.deco}>Über uns</span>
              </Link>
            </div>
            <div className={styles.div3}>
              <Link href="https://carenext.net/loesungen">
                <span className={styles.deco}>Lösungen</span>
              </Link>
            </div>
            <div className={styles.div2}>
              <Link href="https://carenext.net/khzg-foerderung">
                <span className={styles.deco}>KHZG</span>
              </Link>
            </div>
            <div className={styles.div4}>
              <Link href="https://carenext.net/kontakt">
                <span className={styles.deco}>Kontakt</span>
              </Link>
            </div>
            <div className={styles.div5}></div>
            <div className={styles.div6}></div>
            <div className={styles.div7}></div>
            {/*             <div className={styles.div8}>
              <a
                href="tel:+4991114690500"
                style={{
                  textDecoration: "none",
                  color: "#c9c9c9",
                  cursor: "pointer",
                }}
              >
                <span className={styles.icon}>
                  <FaPhone style={{ marginRight: "1rem" }} /> +49 911 14690500
                </span>
              </a>
            </div> */}
            <div className={styles.div8}>
              <a
                href="mailto:kontakt@carenext.net"
                style={{
                  textDecoration: "none",
                  color: "#c9c9c9",
                  cursor: "pointer",
                }}
              >
                <span className={styles.icon}>
                  <MdEmail style={{ marginRight: "1rem" }} />
                  kontakt@carenext.net
                </span>
              </a>
            </div>
            <div className={styles.div9}>
              <span className={styles.icon}>
                <FaMapMarkerAlt style={{ marginRight: "1rem" }} />
                Nürnberg
              </span>
            </div>
            <div className={styles.div10}>
              <Link href="https://www.linkedin.com/company/carenext">
                <span className={styles.icon}>
                  <FaLinkedin style={{ marginRight: "1rem" }} />
                  CareNext
                </span>
              </Link>
            </div>
            <div className={styles.div12}>
              <span>
                Als staatlich gefördertes Start-Up erleichtern wir den Prozess
                der Pflegeüberleitung für alle daran beteiligten Instanzen
              </span>
            </div>
          </div>
          <div className={styles.bottomline}>
            <div className={styles.line} />
            <div className={styles.links}>
              <div>© Copyright CareNext 2021</div>
              <div className={styles.strich}> &nbsp;|&nbsp; </div>
              <div>
                <Link href="/impressum">
                  <span className={styles.deco}>Impressum</span>
                </Link>
                <span> - </span>
                <Link href="https://carenext.net/datenschutzerklaerung-carenext/">
                  <span className={styles.deco}>Datenschutz</span>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
}
