/** @format */

import React, { useState, useEffect } from "react";
import { IconButton, Popover, Button as ButtonMUI } from "@material-ui/core";
import Link from "next/link";
import { useAuth } from "../utils/context";
import { useRouter } from "next/router";
import { Avatar, Badge, Button, Menu, Dropdown } from "antd";
import { FiUser } from "react-icons/fi";
import { FaHeart, FaLock, FaLockOpen } from "react-icons/fa";
import styles from "../styles/Navigation.module.scss";
import { AlertTitle, Alert } from "@material-ui/lab";
import GenerateCert from "./GenerateCert";
import GetCert from "./GetCert";
export default function ButtonAppBar() {
  const router = useRouter();
  const [transparent, setTransparent] = useState(false);
  const [keyPopover, setKeyPopover] = useState(null);
  const [host, setHost] = useState(null);
  const user = useAuth();
  async function signOut() {
    const json = await user.logout();
  }
  useEffect(() => {
    setHost(window?.location?.hostname);
  }, []);
  useEffect(() => {
    if (router.pathname === "/") {
      if (window.pageYOffset === 0) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    } else {
      setTransparent(false);
    }
    function handleScroll() {
      if (router.pathname === "/" && window.pageYOffset === 0) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    }
  }, [router.pathname]);
  const getPaths = () => {
    const d = user.pflegeheim.find((a) => a._id === user.active);
    if (!d) return null;
    if (d.Reha)
      return (
        <>
          <Menu.Item key={1}>
            <Link href="/profil/pflegeplatz">
              <a>Rehaplätze</a>
            </Link>
          </Menu.Item>
          <Menu.Item key={2}>
            <Link href="/profil/anfrage">
              <a>Anfragen</a>
            </Link>
          </Menu.Item>
        </>
      );
    return (
      <>
        <Menu.Item key={1}>
          <Link href="/profil/pflegeplatz">
            <a>Pflegeplätze</a>
          </Link>
        </Menu.Item>
        <Menu.Item key={2}>
          <Link href="/profil/anfrage">
            <a>Anfragen</a>
          </Link>
        </Menu.Item>
        <Menu.Item key={3}>
          <Link href="/profil/pflegepool">
            <a>Pflegepool</a>
          </Link>
        </Menu.Item>
      </>
    );
  };
  const menu = (
    <Menu>
      <Menu.Item key={0}>
        <Link href="/fuer_krankenhaeuser">
          <a>für Krankenhäuser</a>
        </Link>
      </Menu.Item>
      <Menu.Item key={1}>
        <Link href="/fuer_pflegeheime">
          <a>für Pflegeheime</a>
        </Link>
      </Menu.Item>
      <Menu.Item key={2}>
        <Link href="/fuer_pflegedienste">
          <a>für Pflegedienste</a>
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <nav
        style={{
          height: transparent ? "80px" : "60px",
          background: transparent
            ? "linear-gradient(rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)"
            : "white",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          zIndex: 10000,
          top: 0,
          left: 0,
          right: 0,
          transition: "all 0.3s ease-in-out 0s",

          boxShadow: "0px 20px 50px rgba(59, 90, 136, 0.05)",
        }}
      >
        <div
          onClick={() => {
            if (
              ["admin", "sozialdienst", "pflegeheim"].includes(user.user?.type)
            )
              router.push("/profil");
            else router.push("/suche");
          }}
          style={{
            width: "250px",
            cursor: "pointer",
            marginLeft: "1rem",
            display: "flex",
          }}
        >
          {host === "plattform.carenext.net" || host === "localhost" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1417.4 400.94"
              width="110px"
              height="40px"
            >
              <g id="Ebene_2" data-name="Ebene 2">
                <g id="Layer_1" data-name="Layer 1">
                  <path
                    className="cls-1"
                    d="M484.22,284.84c-17.59,17.36-40.32,25.42-65,25.42-63.54,0-90.43-43.75-90.67-88.23-.25-44.73,28.84-90.19,90.67-90.19,23.22,0,45.22,8.8,62.82,26.16l-21.51,20.77c-11.25-11-26.4-16.13-41.31-16.13-41.3,0-59.14,30.79-58.9,59.39.25,28.35,16.62,57.92,58.9,57.92,14.91,0,31.78-6.11,43-17.35Z"
                  />
                  <path
                    className="cls-1"
                    d="M590.54,185.86h28.6V306.35H591l-1.46-17.6c-6.85,14.18-25.67,21-39.11,21.27-35.68.24-62.08-21.76-62.08-64,0-41.55,27.62-63.3,62.82-63,16.13,0,31.52,7.57,38.37,19.55ZM518.2,246c0,23,15.89,36.66,35.68,36.66,46.93,0,46.93-73.07,0-73.07C534.09,209.57,518.2,223,518.2,246Z"
                  />
                  <path
                    className="cls-1"
                    d="M671.94,185.86l2.2,13.93c9.28-14.91,21.75-17.11,34-17.11,12.46,0,24.44,4.89,31,11.49L725.7,220.08c-6.11-5.14-11.73-7.83-21.5-7.83-15.64,0-30.06,8.31-30.06,30.55v63.55H644.32V185.86Z"
                  />
                  <path
                    className="cls-1"
                    d="M775.81,256.74c2,14.91,14.91,25.66,35.93,25.66,11,0,25.42-4.16,32.26-11.24L863.06,290c-12.71,13.19-33.48,19.55-51.81,19.55-41.55,0-66.24-25.66-66.24-64.28,0-36.66,24.93-63.06,64-63.06,40.33,0,65.5,24.93,60.86,74.55Zm65.5-24.69c-1.95-15.64-14.18-23.46-31.28-23.46-16.13,0-29.33,7.82-33.73,23.46Z"
                  />
                  <path
                    className="cls-1"
                    d="M1007.51,135h32.26V306.35h-20v.25L929.79,191V306.35H897.53V135.27h26.15l83.83,106.07Z"
                  />
                  <path
                    className="cls-1"
                    d="M1092.57,256.74c2,14.91,14.91,25.66,35.93,25.66,11,0,25.42-4.16,32.26-11.24L1179.83,290c-12.71,13.19-33.49,19.55-51.82,19.55-41.55,0-66.23-25.66-66.23-64.28,0-36.66,24.93-63.06,64-63.06,40.33,0,65.5,24.93,60.86,74.55Zm65.5-24.69c-2-15.64-14.17-23.46-31.28-23.46-16.13,0-29.33,7.82-33.73,23.46Z"
                  />
                  <path
                    className="cls-1"
                    d="M1284,185.86h35.68v1L1280,245.25l42,60.37v.73h-35.69L1260.24,266l-26.15,40.33H1198.4v-.73l42-60.37-39.59-58.41v-1h35.68l23.71,40.81Z"
                  />
                  <path
                    className="cls-1"
                    d="M1379.27,151.89V186.1h33.24v25.67H1379v52c0,11.49,6.36,17.11,15.65,17.11a34.07,34.07,0,0,0,14.42-3.66l8.31,25.42a66.77,66.77,0,0,1-24.69,5.13c-26.15,1-43.26-13.93-43.26-44v-52H1327V186.1h22.48v-31Z"
                  />
                  <path
                    className="cls-2"
                    d="M217.76,231.26c-7.91,17.5-19.35,32.73-31.52,47.42-18,21.82-38.06,41.64-59.06,60.55-2.55,2.3-4.43,2.52-7.35.35A367.41,367.41,0,0,1,38.21,255.3a232,232,0,0,1-21.7-39.57c-7.4-16.93-8-34.65-2-52.6,6.51-19.41,18.14-34.36,35.87-44.48,11.43-6.51,23.72-10,33.18-9.72,28.33.33,48.66,10.21,65.51,27.53,1.5,1.56,2.59,2.19,3.6,2.1v40.49c-6-.09-12-3-15.09-8.32-7-12.26-16.67-22-29.8-27.24-23.51-9.4-48.33-3.86-61.7,21.43-8.44,15.93-6.23,31.62,1.32,47.12,16.27,33.34,41,59.91,67.79,84.85,7.27,6.75,5.93,7,13.19-.18,19.66-19.44,38.36-39.69,53.4-63,3.48-5.37,6.21-11.22,9.63-16.63,4.42-7,12.67-9.61,18.75-6.32C218.74,215.35,221.58,222.84,217.76,231.26Z"
                  />
                  <path
                    className="cls-1"
                    d="M294.8,192.4c-.91,13.73-5.16,26.77-11.1,39.2-20.87,43.66-52.89,78.8-86.65,112.43C179.4,361.6,160.6,378,142.19,394.77c-3.71,3.38-7.7,6.44-13.34,6.15a14.89,14.89,0,0,1-12.95-8.16c-3.24-5.85-2.71-12,2-16.58,7.64-7.48,16-14.18,24-21.36,29.52-26.7,57.63-54.74,82.4-86,12.16-15.35,23.55-31.23,31.57-49.11a101,101,0,0,0,8.34-27.84c2.62-20.22-9.67-42.32-30.39-49.73-20.44-7.29-43.43,1-56.86,16.39a75.84,75.84,0,0,0-8.9,12.16c-3.24,5.76-9.31,8.44-15.32,8.34V138.56c1.07-.07,2.05-.9,3.37-2.24,19.4-19.77,42.83-30,70.65-27,24.07,2.59,42.57,15.12,55.71,35.5A76.91,76.91,0,0,1,294.8,192.4Z"
                  />
                  <path
                    className="cls-1"
                    d="M255.21.13c25.28-2,47.59,21.3,47.35,47s-21.87,46.72-47,47c-26.08.28-47.94-23-47.74-47.78C208.05,23.32,229.06-2,255.21.13Z"
                  />
                  <path
                    className="cls-2"
                    d="M0,77.48c.17-19.34,15.09-30.8,31.35-30.72C47.16,46.84,61.48,62,61.23,78.05,61,94.84,46.76,109,30.36,108.67,13.68,108.33-.2,96,0,77.48Z"
                  />
                </g>
              </g>
            </svg>
          ) : host === "pflegeangebot.bayern" ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 400.4 400.94"
                width="40px"
                height="40px"
              >
                <g id="Ebene_2" data-name="Ebene 2">
                  <g id="Layer_1" data-name="Layer 1">
                    <path
                      className="cls-2"
                      d="M217.76,231.26c-7.91,17.5-19.35,32.73-31.52,47.42-18,21.82-38.06,41.64-59.06,60.55-2.55,2.3-4.43,2.52-7.35.35A367.41,367.41,0,0,1,38.21,255.3a232,232,0,0,1-21.7-39.57c-7.4-16.93-8-34.65-2-52.6,6.51-19.41,18.14-34.36,35.87-44.48,11.43-6.51,23.72-10,33.18-9.72,28.33.33,48.66,10.21,65.51,27.53,1.5,1.56,2.59,2.19,3.6,2.1v40.49c-6-.09-12-3-15.09-8.32-7-12.26-16.67-22-29.8-27.24-23.51-9.4-48.33-3.86-61.7,21.43-8.44,15.93-6.23,31.62,1.32,47.12,16.27,33.34,41,59.91,67.79,84.85,7.27,6.75,5.93,7,13.19-.18,19.66-19.44,38.36-39.69,53.4-63,3.48-5.37,6.21-11.22,9.63-16.63,4.42-7,12.67-9.61,18.75-6.32C218.74,215.35,221.58,222.84,217.76,231.26Z"
                    />
                    <path
                      className="cls-1"
                      d="M294.8,192.4c-.91,13.73-5.16,26.77-11.1,39.2-20.87,43.66-52.89,78.8-86.65,112.43C179.4,361.6,160.6,378,142.19,394.77c-3.71,3.38-7.7,6.44-13.34,6.15a14.89,14.89,0,0,1-12.95-8.16c-3.24-5.85-2.71-12,2-16.58,7.64-7.48,16-14.18,24-21.36,29.52-26.7,57.63-54.74,82.4-86,12.16-15.35,23.55-31.23,31.57-49.11a101,101,0,0,0,8.34-27.84c2.62-20.22-9.67-42.32-30.39-49.73-20.44-7.29-43.43,1-56.86,16.39a75.84,75.84,0,0,0-8.9,12.16c-3.24,5.76-9.31,8.44-15.32,8.34V138.56c1.07-.07,2.05-.9,3.37-2.24,19.4-19.77,42.83-30,70.65-27,24.07,2.59,42.57,15.12,55.71,35.5A76.91,76.91,0,0,1,294.8,192.4Z"
                    />
                    <path
                      className="cls-1"
                      d="M255.21.13c25.28-2,47.59,21.3,47.35,47s-21.87,46.72-47,47c-26.08.28-47.94-23-47.74-47.78C208.05,23.32,229.06-2,255.21.13Z"
                    />
                    <path
                      className="cls-2"
                      d="M0,77.48c.17-19.34,15.09-30.8,31.35-30.72C47.16,46.84,61.48,62,61.23,78.05,61,94.84,46.76,109,30.36,108.67,13.68,108.33-.2,96,0,77.48Z"
                    />
                  </g>
                </g>
              </svg>
              <div style={{ width: "186px" }}>
                <div
                  style={{ fontSize: 14, color: "#005074", fontWeight: "bold" }}
                >
                  Die Pflegebörse für Deutschland
                </div>
                <div style={{ fontSize: 12, color: "lightgrey" }}>
                  powered by CareNext
                </div>
              </div>
            </>
          ) : null}

          {/* <Link href="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1417.4 400.94"
              width="110px"
              height="40px"
            >
              <g id="Ebene_2" data-name="Ebene 2">
                <g id="Layer_1" data-name="Layer 1">
                  <path
                    className="cls-1"
                    d="M484.22,284.84c-17.59,17.36-40.32,25.42-65,25.42-63.54,0-90.43-43.75-90.67-88.23-.25-44.73,28.84-90.19,90.67-90.19,23.22,0,45.22,8.8,62.82,26.16l-21.51,20.77c-11.25-11-26.4-16.13-41.31-16.13-41.3,0-59.14,30.79-58.9,59.39.25,28.35,16.62,57.92,58.9,57.92,14.91,0,31.78-6.11,43-17.35Z"
                  />
                  <path
                    className="cls-1"
                    d="M590.54,185.86h28.6V306.35H591l-1.46-17.6c-6.85,14.18-25.67,21-39.11,21.27-35.68.24-62.08-21.76-62.08-64,0-41.55,27.62-63.3,62.82-63,16.13,0,31.52,7.57,38.37,19.55ZM518.2,246c0,23,15.89,36.66,35.68,36.66,46.93,0,46.93-73.07,0-73.07C534.09,209.57,518.2,223,518.2,246Z"
                  />
                  <path
                    className="cls-1"
                    d="M671.94,185.86l2.2,13.93c9.28-14.91,21.75-17.11,34-17.11,12.46,0,24.44,4.89,31,11.49L725.7,220.08c-6.11-5.14-11.73-7.83-21.5-7.83-15.64,0-30.06,8.31-30.06,30.55v63.55H644.32V185.86Z"
                  />
                  <path
                    className="cls-1"
                    d="M775.81,256.74c2,14.91,14.91,25.66,35.93,25.66,11,0,25.42-4.16,32.26-11.24L863.06,290c-12.71,13.19-33.48,19.55-51.81,19.55-41.55,0-66.24-25.66-66.24-64.28,0-36.66,24.93-63.06,64-63.06,40.33,0,65.5,24.93,60.86,74.55Zm65.5-24.69c-1.95-15.64-14.18-23.46-31.28-23.46-16.13,0-29.33,7.82-33.73,23.46Z"
                  />
                  <path
                    className="cls-1"
                    d="M1007.51,135h32.26V306.35h-20v.25L929.79,191V306.35H897.53V135.27h26.15l83.83,106.07Z"
                  />
                  <path
                    className="cls-1"
                    d="M1092.57,256.74c2,14.91,14.91,25.66,35.93,25.66,11,0,25.42-4.16,32.26-11.24L1179.83,290c-12.71,13.19-33.49,19.55-51.82,19.55-41.55,0-66.23-25.66-66.23-64.28,0-36.66,24.93-63.06,64-63.06,40.33,0,65.5,24.93,60.86,74.55Zm65.5-24.69c-2-15.64-14.17-23.46-31.28-23.46-16.13,0-29.33,7.82-33.73,23.46Z"
                  />
                  <path
                    className="cls-1"
                    d="M1284,185.86h35.68v1L1280,245.25l42,60.37v.73h-35.69L1260.24,266l-26.15,40.33H1198.4v-.73l42-60.37-39.59-58.41v-1h35.68l23.71,40.81Z"
                  />
                  <path
                    className="cls-1"
                    d="M1379.27,151.89V186.1h33.24v25.67H1379v52c0,11.49,6.36,17.11,15.65,17.11a34.07,34.07,0,0,0,14.42-3.66l8.31,25.42a66.77,66.77,0,0,1-24.69,5.13c-26.15,1-43.26-13.93-43.26-44v-52H1327V186.1h22.48v-31Z"
                  />
                  <path
                    className="cls-2"
                    d="M217.76,231.26c-7.91,17.5-19.35,32.73-31.52,47.42-18,21.82-38.06,41.64-59.06,60.55-2.55,2.3-4.43,2.52-7.35.35A367.41,367.41,0,0,1,38.21,255.3a232,232,0,0,1-21.7-39.57c-7.4-16.93-8-34.65-2-52.6,6.51-19.41,18.14-34.36,35.87-44.48,11.43-6.51,23.72-10,33.18-9.72,28.33.33,48.66,10.21,65.51,27.53,1.5,1.56,2.59,2.19,3.6,2.1v40.49c-6-.09-12-3-15.09-8.32-7-12.26-16.67-22-29.8-27.24-23.51-9.4-48.33-3.86-61.7,21.43-8.44,15.93-6.23,31.62,1.32,47.12,16.27,33.34,41,59.91,67.79,84.85,7.27,6.75,5.93,7,13.19-.18,19.66-19.44,38.36-39.69,53.4-63,3.48-5.37,6.21-11.22,9.63-16.63,4.42-7,12.67-9.61,18.75-6.32C218.74,215.35,221.58,222.84,217.76,231.26Z"
                  />
                  <path
                    className="cls-1"
                    d="M294.8,192.4c-.91,13.73-5.16,26.77-11.1,39.2-20.87,43.66-52.89,78.8-86.65,112.43C179.4,361.6,160.6,378,142.19,394.77c-3.71,3.38-7.7,6.44-13.34,6.15a14.89,14.89,0,0,1-12.95-8.16c-3.24-5.85-2.71-12,2-16.58,7.64-7.48,16-14.18,24-21.36,29.52-26.7,57.63-54.74,82.4-86,12.16-15.35,23.55-31.23,31.57-49.11a101,101,0,0,0,8.34-27.84c2.62-20.22-9.67-42.32-30.39-49.73-20.44-7.29-43.43,1-56.86,16.39a75.84,75.84,0,0,0-8.9,12.16c-3.24,5.76-9.31,8.44-15.32,8.34V138.56c1.07-.07,2.05-.9,3.37-2.24,19.4-19.77,42.83-30,70.65-27,24.07,2.59,42.57,15.12,55.71,35.5A76.91,76.91,0,0,1,294.8,192.4Z"
                  />
                  <path
                    className="cls-1"
                    d="M255.21.13c25.28-2,47.59,21.3,47.35,47s-21.87,46.72-47,47c-26.08.28-47.94-23-47.74-47.78C208.05,23.32,229.06-2,255.21.13Z"
                  />
                  <path
                    className="cls-2"
                    d="M0,77.48c.17-19.34,15.09-30.8,31.35-30.72C47.16,46.84,61.48,62,61.23,78.05,61,94.84,46.76,109,30.36,108.67,13.68,108.33-.2,96,0,77.48Z"
                  />
                </g>
              </g>
            </svg>
          </Link> */}
        </div>
        <div
          className="buttons"
          style={{
            marginLeft: "70px",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginRight: "3rem",
          }}
        >
          {user.user && !user.loading ? (
            <div
              id="navigation"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div style={{ paddingRight: "1rem" }}>
                {user.user.type === "pflegeheim" &&
                  user.pflegeheim.every((ph) => ph.publicKey) && (
                    <>
                      <IconButton
                        onClick={(e) => setKeyPopover(e.currentTarget)}
                        style={{ margin: "0 1rem" }}
                      >
                        {user.privateKey ? <FaLock /> : <FaLockOpen />}
                      </IconButton>
                      <Popover
                        id={"simple-popover"}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        open={Boolean(keyPopover)}
                        anchorEl={keyPopover}
                        onClose={() => setKeyPopover(null)}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        {user.pflegeheim.every((ph) => !ph.publicKey) ? (
                          <>
                            <Alert
                              style={{ maxWidth: "700px" }}
                              severity="warning"
                            >
                              <AlertTitle>
                                Ende-zu-Ende Verschlüsselung
                              </AlertTitle>
                              Das offene Schloss zeigt Ihnen, dass Sie aktuell
                              noch keinen Sicherheitsschlüssel
                              heruntergeladen(Link zum Download) haben, um per
                              Ende-zu-Ende Verschlüsselung mit den Kliniken
                              kommunizieren zu können. Dies ermöglicht es Ihnen
                              ab sofort z. B. ärztliche Fragebögen oder andere
                              patientenbezogenen Daten Ende-zu-Ende
                              verschlüsselt über CareNext einsehen zu können.
                            </Alert>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "1rem 0",
                              }}
                            >
                              <ButtonMUI onClick={() => setKeyPopover(null)}>
                                Abbrechen
                              </ButtonMUI>
                              <GenerateCert />
                            </div>
                          </>
                        ) : user.privateKey ? (
                          <>
                            <Alert
                              style={{ maxWidth: "700px" }}
                              severity="success"
                            >
                              <AlertTitle>
                                Ende-zu-Ende Verschlüsselung
                              </AlertTitle>
                              Das offene Schloss zeigt Ihnen, dass Sie Ihren
                              Sicherheitsschlüssel, während Ihrer aktuellen
                              Sitzung noch nicht hochgeladen haben. Dieser
                              ermöglicht es Ihnen ab sofort z. B. ärztliche
                              Fragebögen oder andere patientenbezogenen Daten
                              Ende-zu-Ende verschlüsselt über CareNext einsehen
                              zu können.
                            </Alert>
                          </>
                        ) : (
                          <>
                            <Alert
                              style={{ maxWidth: "700px" }}
                              severity="info"
                            >
                              <AlertTitle>
                                Ende-zu-Ende Verschlüsselung
                              </AlertTitle>
                              Das geschlossene Schloss zeigt Ihnen, dass Sie
                              während Ihrer aktuellen Sitzung mit einer
                              Ende-zu-Ende Verschlüsselung kommunizierst. Diese
                              Verschlüsselung ermöglicht es Ihnen ab sofort z.
                              B. ärztliche Fragebögen oder andere
                              patientenbezogenen Daten Ende-zu-Ende
                              verschlüsselt über CareNext einsehen zu können.
                            </Alert>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "1rem 0",
                              }}
                            >
                              <ButtonMUI onClick={() => setKeyPopover(null)}>
                                Abbrechen
                              </ButtonMUI>
                              <GetCert handler={() => setKeyPopover(null)} />
                            </div>
                          </>
                        )}
                      </Popover>
                    </>
                  )}
                {/*                 <IconButton>
                  <FaVolumeUp color="#005074" />
                </IconButton> */}
                <Badge
                  size="default"
                  count={
                    [
                      ...user.nachrichten,
                      ...user.messages.filter(
                        (m) =>
                          !m.gelesen &&
                          m.senderId !== user.user.adminId &&
                          m.senderId !== user.user.id &&
                          !user.user.pflegeheimId?.includes(m.senderId) &&
                          (user.user.type !== "sozialdienst" ||
                            m.betreuerId === user.user.id)
                      ),
                    ].length
                  }
                >
                  <Link
                    href={
                      user.user.type === "pflegeheim"
                        ? "/profil/anfrage"
                        : "/profil/pflegebeduerftige"
                    }
                  >
                    <a style={{ color: "#005074", paddingRight: "0.5rem" }}>
                      Anfragen
                    </a>
                  </Link>
                </Badge>
              </div>

              <Dropdown
                arrow
                getPopupContainer={() => document.getElementById("navigation")}
                overlay={
                  <Menu>
                    <Menu.Item key={0}>
                      <Link href="/profil">
                        <a>Profil</a>
                      </Link>
                    </Menu.Item>
                    {user.user.type === "pflegeheim" ? (
                      <>{getPaths()}</>
                    ) : (
                      <Menu.Item key={4}>
                        <Link href="/profil/pflegebeduerftige">
                          <a>Pflegebedürftige</a>
                        </Link>{" "}
                      </Menu.Item>
                    )}
                    <Menu.Item key={5}>
                      <a onClick={signOut}>Abmelden</a>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <Avatar
                  shape="round"
                  icon={<FiUser />}
                  onClick={(e) => e.preventDefault()}
                />
              </Dropdown>
            </div>
          ) : !user.user && !user.loading ? (
            <>
              <div className={styles.menu}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  {/*                   <Link href="https://plattform.carenext.net/khzg">
                    <span className={styles.link}>KHZG</span>
                  </Link>
                  <Dropdown overlay={menu}>
                    <span className={styles.link}>Lösungen</span>
                  </Dropdown>
                  <Link href="/news">
                    <span className={styles.link}>News</span>
                  </Link>
                  <Link href="/ueber_uns">
                    <span className={styles.link}>Über uns</span>
                  </Link> */}
                </div>
              </div>
              {/*               <IconButton>
                <FaVolumeUp color="#005074" />
              </IconButton> */}
              {user.likes && user.likes.length > 0 && (
                <div style={{ marginRight: "2rem", paddingTop: "2px" }}>
                  <Link href="/favoriten">
                    <a target="_blank">
                      <FaHeart color="#65c3af" size={24} />
                    </a>
                  </Link>
                </div>
              )}
              <Button
                type="primary"
                shape="round"
                style={{ background: "#42A392", border: "none" }}
              >
                <Link href="/login">
                  <a>Login</a>
                </Link>
              </Button>
              {/*           für Testphase    
              <Button className="button">
                <Link href="/register">
                  <a className="button">Register</a>
                </Link>
              </Button>
                </>
              ) : (
                <Button
                  type="primary"
                  shape="round"
                  style={{ background: "#65c3af", border: "none" }}
                >
                  <Link href="/kontakt">
                    <a>Kontakt</a>
                  </Link>
                </Button>
              )} */}
            </>
          ) : (
            <></>
          )}
        </div>
      </nav>
      <style jsx>
        {`
          .buttons {
            color: white;
            margin: 7px 30px;
          }
          .button {
            color: white;
          }
        `}
      </style>
    </>
  );
}
