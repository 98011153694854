/** @format */

import React from "react";
import { useAuth } from "../utils/context";
import { Button } from "@material-ui/core";
export default function GenerateCert({ handler, status }) {
  const user = useAuth();
  /* RSA Key Paar generieren, Private Key downloaden und Public Key Uploaden */
  const handleCert = async () => {
    window.crypto.subtle
      .generateKey(
        {
          name: "RSA-OAEP",
          modulusLength: 2048, //can be 1024, 2048, or 4096
          publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
          hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        },
        true, //whether the key is extractable (i.e. can be used in exportKey)
        ["encrypt", "decrypt"] //can be any combination of "sign" and "verify"
      )
      .then(async function (keys) {
        //returns a keypair object
        user.setPrivateKey(keys.privateKey);
        const pubKey = await window.crypto.subtle.exportKey(
          "spki",
          keys.publicKey
        );
        const priKey = await window.crypto.subtle.exportKey(
          "pkcs8",
          keys.privateKey
        );
        return {
          publicKey: pubKey,
          privateKey: priKey,
        };
      })
      .then(function (keydata) {
        const buf = arrayBufferToBuffer(keydata.publicKey);
        fetch(`/api/authentication/${user.user.id}/key`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            key: buf,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              const pem = spkiToPEM(keydata.privateKey);
              const pemBlob = new Blob([pem]);
              if (user.user.type === "pflegeheim") {
                const url = window.URL.createObjectURL(pemBlob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "private_key.pem");
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                const mongoBuffer = {
                  type: "Buffer",
                  data: Array.from(new Uint8Array(keydata.publicKey)),
                };
                user.sendKey(mongoBuffer);
                user.setAnfragen(
                  user.anfragen.map((a) => {
                    if (
                      [
                        "angefragt",
                        "Fragebogen angefordert",
                        "angenommen (Fragebogen angefordert)",
                      ].includes(a.Status)
                    ) {
                      return { ...a, phKey: mongoBuffer };
                    } else {
                      return a;
                    }
                  })
                );
                user.setPflegeheim(
                  user.pflegeheim.map((ph) => {
                    return { ...ph, publicKey: mongoBuffer };
                  })
                );
                if (status) {
                  handler(status, buf);
                }
              }
              if (user.user.type === "sozialdienst") {
                const formData = new FormData();

                formData.append("file", pemBlob, "private_key.pem");
                fetch("http://localhost:5000/key", {
                  method: "POST",
                  body: formData,
                })
                  .then((res) => res.json())
                  .then((data) => {
                    user.handleAlert(data.message, data.success);
                  })
                  .catch((err) =>
                    user.handleAlert("Service nicht verfügbar", false)
                  );
              }
            } else {
              user.setPrivateKey(null);
            }
          });
      })
      .catch(function (err) {
        user.handleAlert("Fehlgeschlagen", false);
      });
    function arrayBufferToBuffer(ab) {
      const buf = Buffer.alloc(ab.byteLength);
      const view = new Uint8Array(ab);
      for (let i = 0; i < buf.length; ++i) {
        buf[i] = view[i];
      }
      return buf;
    }
    function spkiToPEM(keydata) {
      var keydataString = arrayBufferToString(keydata);
      var keydataB64 = window.btoa(keydataString);
      var keydataB64Pem = formatAsPem(keydataB64);
      return keydataB64Pem;
    }

    function arrayBufferToString(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return binary;
    }
    function formatAsPem(str) {
      var finalString = "-----BEGIN RSA PRIVATE KEY-----\n";

      while (str.length > 0) {
        finalString += str.substring(0, 64) + "\n";
        str = str.substring(64);
      }

      finalString = finalString + "-----END RSA PRIVATE KEY-----";

      return finalString;
    }
  };
  return (
    <Button color="primary" variant="contained" onClick={() => handleCert()}>
      {status ? "Schlüssel herunterladen und anfordern" : "Generieren"}
    </Button>
  );
}
