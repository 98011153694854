import { useState, useEffect, useRef } from "react";
import Link from "next/link";
import { setCookie, getCookie } from "cookies-next";
import {
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
} from "@material-ui/core";
import styles from "../styles/CookieBanner.module.scss";
import { Switch } from "@material-ui/core";

export default function CookieBanner() {
  const [cookieConsent, setCookieConsent] = useState(null);
  const [privacySettings, setPrivacySettings] = useState(false);
  const [statisticalCookie, setStatisticalCookie] = useState(true);
  const [showEssentialCookies, setShowEssentialCookies] = useState(false);
  const [showStatisticalCookies, setShowSatisticalCookies] = useState(false);

  useEffect(() => {
    const storedCookieConsent = getCookie("cookie_consent");
    if (storedCookieConsent !== undefined) {
      setCookieConsent(storedCookieConsent);
    }
  }, []);
  useEffect(() => {
    if (cookieConsent !== null) {
      if (window && window._paq) {
        window._paq.push(["disableCookies", !cookieConsent]);
      }
      setCookie("cookie_consent", cookieConsent, {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      });
    }
  }, [cookieConsent]);

  function createData(title, text) {
    return { title, text };
  }

  const bannerRef = useRef(null);

  useEffect(() => {
    if (bannerRef.current && document.body.contains(bannerRef.current)) {
      bannerRef.current.focus();
    }
  }, []);

  //TODO: in json-Datei auslagern
  const cookieDetailsMatomo = [
    createData("Name", "Matomo"),
    createData("Anbieter", "Matomo gehostet durch die CareNext GmbH"),
    createData(
      "Zweck",
      "Cookie von Matomo für Website-Analysen. Erzeugt statistische Daten darüber, wie der Besucher die Website nutzt."
    ),
    createData(
      "Datenschutzerklärung",
      <Link href="/datenschutz">
        <span>https://www.carenext.net/datenschutz</span>
      </Link>
    ),
    createData("Cookie Name", "	_pk_*.*"),
    createData("Cookie Laufzeit", "13 Monate"),
  ];

  const cookieDetailsMatomoTagManager = [
    createData("Name", "Matomo Tag Manager"),
    createData("Anbieter", "CareNext GmbH"),
    createData(
      "Zweck",
      "Matomo Tag Manager zur Steuerung der erweiterten Script- und Ereignisbehandlung."
    ),
    createData(
      "Datenschutzerklärung",

      <Link href="/datenschutz">
        <span>https://www.carenext.net/datenschutz</span>
      </Link>
    ),
  ];

  const handleChange = (event) => {
    setStatisticalCookie(event.target.checked);
  };

  const saveAuswahl = () => {
    if (statisticalCookie === true) {
      setCookieConsent(true);
      setPrivacySettings(true);
    } else {
      setCookieConsent(false);
    }
  };

  return (
    <div
      style={{
        // erst anzeigen, wenn Datenschutzbestimmungen eingefügt wurden
        // solange kein tracking mit Cookies möglich
        //display: "none",
        display: cookieConsent !== null ? "none" : "flex",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        flexDirection: "column",
        justifyContent: "end",
        alignItems: "center",
        backgroundColor: "rgba(0 0 0 / 0.5)",
        zIndex: "100000",
      }}
      ref={bannerRef}
      tabIndex="-1"
    >
      <div
        style={{
          borderRadius: "1rem",
          backgroundColor: "white",
          padding: "1rem 1rem 2rem 1rem",
          margin: "1rem",
          overflowY: "auto",
          //maxHeight: "90vh",
        }}
      >
        {!privacySettings && (
          <div>
            <div>
              <h1 className={styles.heading}>Datenschutzeinstellungen</h1>
              <p>
                Wir nutzen Cookies auf unserer Website. Einige von ihnen sind
                essenziell, während andere uns helfen, diese Website und Ihre
                Erfahrung zu verbessern. Wenn Sie unter 16 Jahre alt sind und
                Ihre Zustimmung zu freiwilligen Diensten geben möchten, müssen
                Sie Ihre Erziehungsberechtigten um Erlaubnis bitten.
                Personenbezogene Daten können verarbeitet werden (z. B.
                IP-Adressen), z. B. für personalisierte Anzeigen und Inhalte
                oder Anzeigen- und Inhaltsmessung. Weitere Informationen über
                die Verwendung Ihrer Daten finden Sie in unserer
                <Link href="/datenschutz"> Datenschutzerklärung</Link>. Sie
                können Ihre Auswahl jederzeit unter Einstellungen widerrufen
                oder anpassen.
              </p>
            </div>

            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  onClick={() => setPrivacySettings(true)}
                >
                  Individuelle Datenschutzeinstellungen
                </Button>
                {/* <Button variant="text">Nur notwendige Cookies</Button> */}
                <Button
                  variant="contained"
                  color="primary"
                  style={{ background: "#42A392", marginLeft: "1rem" }}
                  onClick={() => setCookieConsent(true)}
                >
                  Alle akzeptieren
                </Button>
              </div>
            </div>
          </div>
        )}

        {privacySettings && (
          <div>
            <h1 className={styles.heading}>
              Individuelle Datenschutzeinstellungen
            </h1>
            <p>
              Wenn Sie unter 16 Jahre alt sind und Ihre Zustimmung zu
              freiwilligen Diensten geben möchten, müssen Sie Ihre
              Erziehungsberechtigten um Erlaubnis bitten. Wir verwenden Cookies
              und andere Technologien auf unserer Webseite. Einige von ihnen
              sind essenziell, während andere uns helfen, diese Webseite und
              Ihre Erfahrung zu verbessern. Personenbezogene Daten können
              verarbeitet werden (z. B. IP-Adressen), z. B. für personalisierte
              Anzeigen und Inhalte oder Anzeigen- und Inhaltsmessung. Weitere
              Informationen über die Verwendung Ihrer Daten finden Sie in
              unserer <Link href="/datenschutz">Datenschutzerklärung</Link>.
              Hier finden Sie eine Übersicht über alle verwendeten Cookies. Sie
              können Ihre Einwilligung zu ganzen Kategorien geben oder sich
              weitere Informationen anzeigen lassen und so nur bestimmte Cookies
              auswählen.
            </p>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setCookieConsent(false)}
              >
                Nur Notwendige Cookies
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={saveAuswahl}
                style={{ marginLeft: "1rem" }}
              >
                Auswahl speichern
              </Button>

              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ background: "#42A392", marginLeft: "1rem" }}
                onClick={() => setCookieConsent(true)}
              >
                Alle akzeptieren
              </Button>
            </div>

            <div className={styles.backButton}>
              <Button variant="text" onClick={() => setPrivacySettings(false)}>
                Zurück
              </Button>
            </div>

            <div className={styles.bannerContainer}>
              <div style={{ display: "flex" }}>
                <h2 className={styles.subHeading}>Notwendige Cookies</h2>
                <Switch defaultChecked disabled></Switch>
              </div>
              <p className={styles.bannerText}>
                Diese Cookies sind für die Funktion der Website notwendig und
                können in unseren Systemen nicht ausgeschaltet werden. Sie
                werden in der Regel nur als Reaktion auf eine Ihrer Aktionen
                eingestellt, die zu einer Anfrage nach einem Service führen, wie
                beispielsweise das Festlegen deiner Datenschutzeinstellungen,
                das Einloggen oder das Ausfüllen von Formularen. Sie können
                Ihren Browser so einstellen, dass diese Cookies blockiert werden
                oder Sie gewarnt werden, dann könnte es jedoch sein, dass einige
                Teile der Website nicht funktionieren.
              </p>

              {/* {showEssentialCookies && <div></div>}

              {!showEssentialCookies ? (
                <div
                  className={styles.moreInformationsLabel}
                  onClick={() => setShowEssentialCookies((prev) => !prev)}
                >
                  <span>Mehr Informationen anzeigen</span>
                </div>
              ) : (
                <div
                  className={styles.moreInformationsLabel}
                  onClick={() => setShowEssentialCookies((prev) => !prev)}
                >
                  <span>Mehr Informationen ausblenden</span>
                </div>
              )} */}
            </div>
            <div className={styles.bannerContainer}>
              <div style={{ display: "flex" }}>
                <h2 className={styles.subHeading}>Statistische Cookies</h2>
                <Switch
                  checked={statisticalCookie}
                  onChange={handleChange}
                  defaultChecked
                ></Switch>
              </div>
              <p className={styles.bannerText}>
                Diese Cookies sind für die Funktion der Website notwendig und
                können in unseren Systemen nicht ausgeschaltet werden. Sie
                werden in der Regel nur als Reaktion auf eine Ihrer Aktionen
                eingestellt, die zu einer Anfrage nach einem Service führen, wie
                beispielsweise das Festlegen deiner Datenschutzeinstellungen,
                das Einloggen oder das Ausfüllen von Formularen. Sie können
                Ihren Browser so einstellen, dass diese Cookies blockiert werden
                oder Sie gewarnt werden, dann könnte es jedoch sein, dass einige
                Teile der Website nicht funktionieren.
              </p>

              {showStatisticalCookies && (
                <div>
                  <div className={styles.cookieInformationTable}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {cookieDetailsMatomo.map((cookieDetail) => (
                            <TableRow key={cookieDetail.title}>
                              <TableCell component="th" scope="row">
                                {cookieDetail.title}
                              </TableCell>
                              <TableCell align="right">
                                {cookieDetail.text}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className={styles.cookieInformationTable}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {cookieDetailsMatomoTagManager.map((cookieDetail) => (
                            <TableRow key={cookieDetail.title}>
                              <TableCell component="th" scope="row">
                                {cookieDetail.title}
                              </TableCell>
                              <TableCell align="right">
                                {cookieDetail.text}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              )}

              {!showStatisticalCookies ? (
                <div
                  className={styles.moreInformationsLabel}
                  onClick={() => setShowSatisticalCookies((prev) => !prev)}
                >
                  <span>Mehr Informationen anzeigen</span>
                </div>
              ) : (
                <div
                  className={styles.moreInformationsLabel}
                  onClick={() => setShowSatisticalCookies((prev) => !prev)}
                >
                  <span>Mehr Informationen ausblenden</span>
                </div>
              )}
            </div>

            {/* <div className={styles.bannerContainer}>
              <div style={{ display: "flex" }}>
                <h2 className={styles.subHeading}>Marketing Cookies</h2>
                <Switch></Switch>
              </div>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
