/** @format */

import React from "react";
import AlertMui from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import { useAuth } from "../utils/context";

/* globale Alert Funktion */

export default function Alert() {
  const user = useAuth();
  return (
    <Snackbar
      open={user.alert}
      autoHideDuration={8000}
      onClose={() => user.setAlert(false)}
    >
      <AlertMui onClose={() => user.setAlert(false)} severity={user.response}>
        {user.message}
      </AlertMui>
    </Snackbar>
  );
}
