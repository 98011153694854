/** @format */

import { createContext, useContext, useState, useEffect, useRef } from "react";
import { notification } from "antd";
import { FaPlay } from "react-icons/fa";
import { io } from "socket.io-client";
import { useRouter } from "next/router";
import { useIdleTimer } from "react-idle-timer";
import { generateDataKey, encryptWithAESCBC } from "../WebCryptoApi/index";
import { arrayBufferToString, blobToArrayBuffer } from "../WebCryptoApi/utils";

const AuthContext = createContext();
const openNotificationWithIcon = (type, art, count) => {
  const plural =
    count === 1 ? art : art === "Nachricht" ? "Nachrichten" : "Anfragen";
  notification[type]({
    message: `Neue ${plural}`,
    top: 64,
    description: `Sie haben ${
      count === 1 ? "eine" : count
    } ${plural} erhalten.`,
  });
};
export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [support, setSupport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(true);
  const [pflegefälle, setPflegefälle] = useState([]);
  const [anfragen, setAnfragen] = useState([]);
  const [pflegeheim, setPflegeheim] = useState([]);
  const [sd, setSd] = useState();
  const [likes, setLikes] = useState(null);
  const [nachrichten, setNachrichten] = useState([]);
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [arrivalAdminMessage, setArrivalAdminMessage] = useState(null);
  const [arrivalAdmins, setArrivalAdmins] = useState(null);
  const [arrivalAnfrage, setArrivalAnfrage] = useState(null);
  const [arrivalAnfragen, setArrivalAnfragen] = useState(null);
  const [arrivalKey, setArrivalKey] = useState(null);
  const [active, setActive] = useState(null);
  const [audio, setAudio] = useState(null);
  const [chat, setChat] = useState(null);
  const [admins, setAdmins] = useState([]);
  const socket = useRef();
  const router = useRouter();
  const [reqStopp, setReqStopp] = useState(null);
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("success");
  const [alert, setAlert] = useState(false);
  const timer = useRef(null);
  const exp = useRef(null);
  const [privateKey, setPrivateKey] = useState(null);
  const [reconnect, setReconnect] = useState(false);
  const handleOnActive = (e) => {
    const u = userRef.current;
    if (!u) {
      return;
    }
    /*     if (new Date().getTime() > exp.current * 1000) {
      logout(true);
    } else {
      if (!socket.current?.connected) {
        socket.current.connect();
        setReconnect(true);
        socket.current.emit("addUser");
      }
      handleLogout(exp.current);
    } */
  };
  useEffect(() => {
    if (reconnect) {
      if (user) {
        handleReconnect();
      } else {
        setReconnect(false);
      }
    }
  }, [reconnect]);
  const userRef = useRef(!!user);
  useEffect(() => {
    userRef.current = !!user;
  }, [user]);
  useIdleTimer({
    timeout: 1000 * 60 * 60,
    //timeout: 1000,
    onActive: handleOnActive,
    debounce: 500,
    crossTab: true,
  });
  // video call
  /*   const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [name, setName] = useState("");
  const [call, setCall] = useState({});
  const [me, setMe] = useState("");
  const myVideo = useRef();
  const userVideo = useRef();
  const connectionRef = useRef(); */
  const playAudio = () => {
    if (audio) {
      try {
        audio.play();
      } catch {}
    }
  };
  const logout = async (auto) => {
    const resp = await fetch(`/api/authentication/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    socket.current.disconnect();
    const json = await resp.json();
    router.push("/login");
    setPflegeheim([]);
    setUser(null);
    setPflegefälle([]);
    setAnfragen([]);
    setNachrichten([]);
    setMessages([]);
    exp.current = null;
    handleAlert(
      auto
        ? "Sie wurden aus Sicherheitsgründen wegen Zeitüberschreitung automatisch abgemeldet. Bitte melden Sie sich erneut an."
        : "Abgemeldet",
      "warning"
    );
    return json;
  };
  const login = async (email, password, datenschutz) => {
    setLoading(true);
    const resp = await fetch(`/api/authentication/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        datenschutz: datenschutz,
      }),
    });

    const json = await resp.json();
    if (json.success) {
      if (json.data.type === "pflegeheim" && !json.datenschutz) {
        return json;
      } else {
        setUser(json.data);

        if (json.data.type !== "admin") {
          setPflegefälle(json.pflegefälle);
          setAnfragen(json.anfragen);
          setMessages(json.messages);
          handleNotification(json.data, json.messages, json.anfragen);
        }
        if (json.data.type === "pflegeheim") {
          setPflegeheim(json.pflegeheim);
          setActive(json.data.active || json.data.pflegeheimId[0]);
        }
        if (json.data.type === "sozialdienst") {
          setSd(json.sd);
        }
        socket.current.disconnect();

        socket.current.connect();
        if (json.data.type === "admin") {
          setMessages(json.messages);
          setAdmins(json.admins);
          socket.current.on("admin get support message", (data) => {
            setArrivalMessage({ ...data, support: true });
          });
          socket.current.on("get users", (data) => {
            setSupport(data.supportUsers);
            setArrivalAdmins({ users: data.adminUsers, type: "GET" });
          });
          socket.current.on("get admin message", (data) => {
            setArrivalMessage(data);
          });
          socket.current.on("user left support", (data) => {
            setArrivalMessage({ id: data, disconnected: true });
          });
        }
        socket.current.emit("addUser");

        exp.current = json.expires;
        handleLogout(json.expires);
      }
    }
    setLoading(false);
    setInit(false);
    return json;
    /* const json = await resp.json();
    if (resp.status === 200) {
      if (json.data.type === "pflegeheim" && !json.datenschutz) {
        return json;
      } else {
        await setUser(json.data);
        if (!socket.current.connected) {
          socket.current.connect();
          socket.current.emit("addUser");
        } else {
          socket.current.emit("addUser");
        }
        if (json.data.type === "sozialdienst") {
          fetchPflegefälle(json.data.id);
        }
        if (json.data.type === "pflegeheim") {
          await fetchPflegeheim(json.data.pflegeheimId);
          await setActive(json.data.pflegeheimId[0]);
        }
        if (json.data.type === "admin") {
          socket.current.on("admin get support message", (data) => {
            setArrivalMessage({ ...data, support: true });
          });
          socket.current.on("support user", (data) => {
            setSupport(data);
          });
          socket.current.on("user left support", (data) => {
            setArrivalMessage({ id: data, disconnected: true });
          });
        }
        fetchAnfragen(json.data.id, json.data.type);
        handleLogout(json.expires);
        await fetchMessages(json.data.id, json.data.type);
      }
    }
    setLoading(false);
    setInit(false);
    return json; */
  };
  const getUser = async () => {
    //evtl. eine Route für alles
    setLoading(true);
    const resp = await fetch(`/api/authentication/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.success) {
          setUser(json.data);
          if (json.data.type !== "admin") {
            setPflegefälle(json.pflegefälle);
            setAnfragen(json.anfragen);
            setMessages(json.messages);
            handleNotification(json.data, json.messages, json.anfragen);
          }
          if (json.data.type === "pflegeheim") {
            setPflegeheim(json.pflegeheim);
            setActive(json.data.active || json.data.pflegeheimId[0]);
          }
          if (json.data.type === "sozialdienst") {
            setSd(json.sd);
          }
          if (!socket.current.connected) {
            socket.current.connect();
          }
          if (json.data.type === "admin") {
            setMessages(json.messages);
            setAdmins(json.admins);
            socket.current.on("admin get support message", (data) => {
              setArrivalMessage({ ...data, support: true });
            });
            socket.current.on("get admin message", (data) => {
              setArrivalMessage(data);
            });
            socket.current.on("get users", (data) => {
              setSupport(data.supportUsers);
              setArrivalAdmins({ users: data.adminUsers, type: "GET" });
            });
            socket.current.on("user left support", (data) => {
              setArrivalMessage({ id: data, disconnected: true });
            });
          }
          socket.current.emit("addUser");
          exp.current = json.expires;
          handleLogout(json.expires);
        }
        setLoading(false);
        setInit(false);
      })
      .catch((e) => {
        console.log(e);
      });
    /* const json = await resp.json();
    if (resp.status === 200) {
      await setUser(json.data);
      if (json.data.type === "user") {
        await fetchPflegefälle(json.data.id);
        await fetchAnfragen(json.data.id, json.data.type);
      }
      if (json.data.type === "sozialdienst") {
        await fetchAnfragen(json.data.adminId, json.data.type);
        await fetchPflegefälle(json.data.adminId);
        socket.current.emit("addUser", json.data.adminId);
      }

      if (json.data.type === "pflegeheim") {
        await fetchPflegeheim(json.data.pflegeheimId);
        await setActive(json.data.pflegeheimId[0]);
        await fetchAnfragen(json.data.id, json.data.type);
        socket.current.emit("addUser", (json.data.pflegeheimId, "pflegeheim"));
      }

      if (json.data.type === "admin") {
        socket.current.emit("addUser", json.data.id);
        socket.current.on("admin get support message", (data) => {
          setArrivalMessage({ ...data, support: true });
        });
        socket.current.on("support users", (data) => {
          setSupport(data);
        });
        socket.current.on("user left support", (data) => {
          setArrivalMessage({ id: data, disconnected: true });
        });
      }
      handleLogout(json.expires);
      await fetchMessages(json.data.id, json.data.type);
    }
    setLoading(false);
    setInit(false);
    return json; */
  };
  const fetchPflegeheim = async (ids) => {
    const resp = await fetch(`/api/pflegeheime/all`);
    const json = await resp.json();

    if (resp.status === 200) {
      setPflegeheim(json.data);
    }
    return json;
  };
  const fetchPflegefälle = async (id) => {
    const resp = await fetch(`/api/authentication/${id}/pflegefall`);
    const json = await resp.json();

    if (resp.status === 200) {
      setPflegefälle(json.data);
    }
    return json;
  };
  const fetchAnfragen = async (id, typ) => {
    const resp = await fetch(`/api/authentication/${id}/anfragen`);
    const json = await resp.json();
    if (resp.status === 200) {
      setAnfragen(json.data);
      handleNotification(user, null, json.data);
    }
    return json;
  };
  const fetchMessages = async (text) => {
    const time = messages[messages.length - 1]?.createdAt.toString() || null;
    fetch(`/api/authentication/${text}/messages${time ? `?time=${time}` : ""}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          const newMessages = time
            ? data.data
            : data.data.filter((m) => !messages.includes(m._id));
          setMessages([...messages, ...newMessages]);
          handleNotification(user, data.messages, null);
        }
      });
  };
  const handleReconnect = () => {
    const messageId =
      messages.length > 0 ? messages[messages.length - 1]._id : null;
    const anfrageId =
      anfragen.length > 0
        ? anfragen.reduce((prev, curr) =>
            new Date(prev.updatedAt) > new Date(curr.updatedAt) ? prev : curr
          )._id
        : null;
    let text = "";
    if (messageId && anfrageId) {
      text = `?messageId=${messageId}&anfrageId=${anfrageId}`;
    } else if (messageId || anfrageId) {
      if (messageId) text = `?messageId=${messageId}`;
      if (anfrageId) text = `?anfrageId=${anfrageId}`;
    }
    fetch(`/api/authentication/${user.id}/reconnect${text}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          if (data.messages > 0) {
            if (messageId) {
              setMessages([...messages, ...data.messages]);
            } else if (!messageId) {
              setMessages(data.messages);
            }
          }
          if (data.anfragen > 0) {
            if (anfrageId) {
              let temp = [...anfragen];
              data.anfragen.forEach((d) => {
                const i = temp.findIndex((t) => t._id === d._id);
                if (i) {
                  temp[i] = d;
                } else {
                  temp.push(d);
                }
              });
              setAnfragen(temp);
            } else if (!anfrageId) {
              setAnfragen(data.anfragen);
            }
          }
          handleNotification(user, data.messages, data.anfragen);
        }
        setReconnect(false);
      });
  };
  const handleLogout = (expires) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(
      () => logout(true),
      new Date(expires * 1000) - new Date()
    );
  };
  const sendMessage = async (data) => {
    if (data.file) {
      // Generierung AES-Schlüssel um PDF Datei zu verschlüsseln
      const aesKey = await generateDataKey().then((cryptoKey) => {
        //setAESKey(cryptoKey)
        return cryptoKey;
      });
      // Initalisierungsvektor zum AES-Schlüssel
      const iv = window.crypto.getRandomValues(new Uint8Array(16));
      const formData = new FormData();
      // Input Datei wird mit AES verschlüsselt
      const encryptedArrayBuffer = await blobToArrayBuffer(data.file_data).then(
        async (plainBuffer) => {
          const encryptedBuffer = await encryptWithAESCBC(
            plainBuffer,
            aesKey,
            iv
          );
          return new Uint8Array(encryptedBuffer);
        }
      );
      //neuer Blob mit verschlüsselter Datei
      const blob = new Blob([iv, encryptedArrayBuffer]);
      const aesKeyAsString = await window.crypto.subtle
        .exportKey("raw", aesKey)
        .then((buffer) => {
          return arrayBufferToString(buffer);
        });
      formData.append("file", blob);
      formData.append("aesKey", aesKeyAsString);
      formData.append("iv", iv);
      const obj = {
        file: true,
      };
      for (const [key, value] of Object.entries(data)) {
        if (key !== "file" && key !== "file_data") {
          formData.append(`${key}`, value);
          obj[`${key}`] = value;
        }
      }
      const res = await fetch(`/api/authentication/${user.id}/messages/file`, {
        method: "POST",
        body: formData,
      });
      const d = await res.json();
      const timestamp = new Date();
      if (d.success) {
        socket.current.emit("sendMessage", { ...data, _id: d.id });
        setMessages([
          ...messages,
          { ...data, createdAt: timestamp, _id: d.id },
        ]);
      }
      return { success: d.success, createdAt: timestamp, _id: d.id };
    } else {
      const res = await fetch(`/api/authentication/${user.id}/messages`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const d = await res.json();
      const timestamp = new Date();
      if (d.success) {
        socket.current.emit("sendMessage", { ...data, _id: d.message._id });
        setMessages([
          ...messages,
          { ...data, createdAt: timestamp, _id: d.message._id },
        ]);
      }
      return { success: d.success, createdAt: timestamp, _id: d.message._id };
    }
  };
  const handleNotification = (dat, mess, anfr) => {
    if (mess) {
      let countMess = 0;
      mess.forEach((d) => {
        if (
          (d.receiverId === dat.adminId ||
            dat.pflegeheimId?.includes(d.receiverId)) &&
          !d.gelesen
        ) {
          const anfrager =
            d.userId === dat.adminId || dat.pflegeheimId?.includes(d.userId);
          if (!anfrager || dat.id === d.betreuerId) ++countMess;
        }
      });
      if (countMess > 0) {
        openNotificationWithIcon("info", "Nachricht", countMess);
        if (dat?.ton) {
          playAudio();
        }
      }
    }
    if (anfr) {
      let newArr = [...nachrichten];
      let countAnfrage = 0;
      anfr.forEach((a) => {
        const anfrager =
          a.userId === dat.adminId || dat.pflegeheimId?.includes(a.userId);
        if (
          !a.gelesen &&
          (a.senderId !== dat.adminId ||
            (dat.type === "user" && a.senderId !== dat.id)) &&
          !dat.pflegeheimId?.includes(a.senderId) &&
          !nachrichten.some((e) => e._id === a._id && e.Status === a.Status)
        ) {
          if (!anfrager || dat.id === a.betreuerId) {
            ++countAnfrage;
            if (
              (!anfrager &&
                (router.route !== "/profil/anfrage" ||
                  a.pflegeheimId !== active)) ||
              (anfrager && router.route !== "/profil/pflegebeduerftige")
            ) {
              newArr = [a, ...newArr];
            }
          }
        }
      });
      if (countAnfrage > 0) {
        setMessages(messages.filter((m) => !m.löschstatus));
        openNotificationWithIcon("info", "Anfrage", countAnfrage);
        if (dat.ton) {
          playAudio();
        }
        setNachrichten(newArr);
      }
    }
  };
  useEffect(() => {
    if (arrivalAnfrage) {
      if (
        arrivalAnfrage.Status === "angefragt" ||
        arrivalAnfrage.Status === "angefragt inkl. Fragebogen" ||
        arrivalAnfrage.Status === "Poolanfrage"
      ) {
        setAnfragen([...anfragen, arrivalAnfrage]);
      } else {
        setAnfragen(
          anfragen.map((a) =>
            a._id === arrivalAnfrage._id ? { ...a, ...arrivalAnfrage } : a
          )
        );
      }
      handleNotification(user, null, [arrivalAnfrage]);
      setArrivalAnfrage(null);
    }
  }, [arrivalAnfrage]);
  useEffect(() => {
    if (arrivalAnfragen) {
      if (arrivalAnfragen.every((d) => d.Status.includes("angefragt"))) {
        setAnfragen([
          ...anfragen,
          ...arrivalAnfragen.map((a) =>
            a.senderId === user.adminId
              ? { ...a, pflegefallId: a.pflegefallId._id }
              : a
          ),
        ]);
      } else {
        setAnfragen(
          anfragen.map((a) => arrivalAnfragen.find((d) => d._id === a._id) || a)
        );
      }
      handleNotification(user, null, arrivalAnfragen);
      setArrivalAnfragen(null);
    }
  }, [arrivalAnfragen]);
  const sendAnfragen = (data) => {
    socket.current.emit("sendAnfragen", data);
  };
  const sendAnfrage = (data, unregistriert) => {
    if (unregistriert) {
      socket.current.emit("sendAblehnen", data);
    } else {
      socket.current.emit("sendAnfrage", data);
    }
  };
  const sendSupportMessage = (id, text) => {
    socket.current.emit("support answer", {
      id,
      text,
      name: user.data.Name,
      createdAt: new Date(),
    });
    setMessages([
      ...messages,
      { text, id, createdAt: new Date(), sender: true },
    ]);
  };
  const sendAdminMessage = async (id, text) => {
    const data = {
      receiverId: id,
      senderId: user.id,
      text,
      createdAt: new Date(),
      gelesen: false,
    };
    const res = await fetch(`/api/authentication/${user.id}/messages`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const d = await res.json();
    const timestamp = new Date();
    if (d.success) {
      socket.current.emit("send admin message", {
        ...data,
        _id: d.message._id,
      });
      setMessages([
        ...messages,
        { ...data, createdAt: timestamp, _id: d.message._id },
      ]);
    }
    return { success: d.success, createdAt: timestamp, _id: d.message._id };
  };
  const setGelesen = async (refresh) => {
    await fetch(
      `/api/authentication/${
        user.type === "pflegeheim" ? active : user.id
      }/messages?anfrageId=${chat}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success && refresh) {
          const mes = messages.map((m) =>
            m.senderId === chat || m.anfrageId === chat
              ? { ...m, gelesen: true }
              : m
          );
          setMessages(mes);
        }
      });
  };
  useEffect(() => {
    if (chat) {
      setGelesen(true);
    }
  }, [chat]);
  useEffect(() => {
    if (pflegeheim && active) {
      const ph = pflegeheim.find((p) => p._id === active);
      if (ph && ph.request_stopp_from !== reqStopp) {
        setReqStopp(ph.request_stopp_from);
      }
    }
  }, [pflegeheim, active]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        !init &&
        !loading &&
        user &&
        (!socket.current || !socket.current.connected)
      ) {
        setReconnect(true);
      }
    }, 1000 * 60 * 5);
    return () => clearInterval(interval);
  });
  useEffect(() => {
    if (arrivalMessage) {
      if (
        arrivalMessage.anfrageId !== chat &&
        (user.type === "pflegeheim" || user.id === arrivalMessage.betreuerId)
      ) {
        openNotificationWithIcon("info", "Nachricht", 1);
      }
      if (
        user &&
        user.data &&
        user.data.ton &&
        (user.type === "pflegeheim" || user.id === arrivalMessage.betreuerId)
      ) {
        playAudio();
      }
      const obj = {
        ...arrivalMessage,
        createdAt: new Date(),
        gelesen: arrivalMessage.anfrageId === chat,
      };
      if (obj.gelesen) {
        setGelesen(false);
      }
      if (arrivalMessage.support && arrivalMessage.name) {
        setSupport([...support, arrivalMessage]);
      }
      setMessages((prev) => [...prev, obj]);
    }
  }, [arrivalMessage]);
  useEffect(() => {
    if (arrivalAdminMessage) {
      if (
        arrivalAdminMessage.senderId !== chat &&
        arrivalAdminMessage.senderId !== user.id
      ) {
        openNotificationWithIcon("info", "Nachricht", 1);
      }
      if (user?.data?.ton && arrivalAdminMessage.senderId !== user.id) {
        playAudio();
      }
      const obj = {
        ...arrivalAdminMessage,
        createdAt: new Date(),
        gelesen: arrivalAdminMessage.senderId === chat,
      };
      if (obj.gelesen) {
        setGelesen(false);
      }
      setMessages((prev) => [...prev, obj]);
    }
  }, [arrivalAdminMessage]);
  useEffect(() => {
    if (arrivalAdmins) {
      if (arrivalAdmins.type === "GET") {
        setAdmins(
          admins.map((a) =>
            arrivalAdmins.users.includes(a._id)
              ? { ...a, online: true }
              : { ...a, online: false }
          )
        );
      } else if (arrivalAdmins.type === "POST") {
        setAdmins(
          admins.map((a) =>
            a._id === arrivalAdmins.users ? { ...a, online: true } : a
          )
        );
      } else if (arrivalAdmins.type === "DELETE") {
        setAdmins(
          admins.map((a) =>
            a._id === arrivalAdmins.users ? { ...a, online: false } : a
          )
        );
      }
      setArrivalAdmins(null);
    }
  }, [arrivalAdmins]);
  const sendKey = (data) => {
    socket.current.emit("sendKey", {
      key: data,
      ids: anfragen
        .filter((a) =>
          [
            "angenommen (Fragebogen angefordert)",
            "angefragt",
            "Fragebogen angefordert",
          ].includes(a.Status)
        )
        .map((a) => a.userId),
    });
  };
  useEffect(() => {
    if (arrivalKey) {
      setAnfragen(
        anfragen.map((a) => {
          if (arrivalKey.pflegeheimId.includes(a.pflegeheimId)) {
            if (!arrivalKey.key) {
              return {
                ...a,
                phKey: null,
              };
            }
            return {
              ...a,
              phKey: arrivalKey.key,
            };
          }
          return a;
        })
      );
      setArrivalKey(null);
    }
  }, [arrivalKey]);

  useEffect(() => {
    /*     navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((currentStream) => {
        setStream(currentStream);
        myVideo.current.srcObject = currentStream;
      }); */
    setAudio(new Audio("/notification_decorative-02.wav"));
    //if (hasCookie("auth")) {
    getUser();
    /*     } else {
      setInit(false);
    } */
    const oldLikes = localStorage.getItem("likes");
    setLikes(oldLikes ? JSON.parse(oldLikes) : []);
    if (!socket.current?.connected) {
      socket.current = io({
        withCredentials: true,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 60000,
        reconnectionAttempts: 100,
      });
      socket.current.on("getMessage", (data) => {
        setArrivalMessage(data);
      });
      socket.current.on("getSignal", (data) => {});
      socket.current.on("getAdminMessage", (data) => {
        setArrivalAdminMessage(data);
      });
      socket.current.on("getAnfragen", (data) => {
        setArrivalAnfragen(data);
      });
      socket.current.on("getAnfrage", (data) => {
        setArrivalAnfrage(data);
      });

      socket.current.on("getKey", (data) => {
        setArrivalKey(data);
      });
      socket.current.on("getSignal", (data) => {});
      socket.current.on("admin joined", (data) => {
        setArrivalAdmins({ users: data, type: "POST" });
      });
      socket.current.on("admin left", (data) => {
        setArrivalAdmins({ users: data, type: "DELETE" });
      });
      socket.current.on("getAnfragen", (data) => {
        setArrivalAnfragen(data);
      });
      socket.current.on("getAnfrage", (data) => {
        setArrivalAnfrage(data);
      });

      socket.current.on("getUser", (data) => {});
      socket.current.io.on("reconnect", () => {
        setReconnect(true);
        socket.current.emit("addUser");
      });
    }

    /*     socket.current.on("me", (id) => setMe(id));
    socket.current.on("callUser", ({ from, name: callerName, signal }) => {
      setCall({ isReceivingCall: true, from, name: callerName, signal });
    }); */
    //socket.current.on("user-connected", (userId) => {});
  }, []);
  const handleAlert = (message, response) => {
    let res;
    if (typeof response === "string") res = response;
    else res = response ? "success" : "error";
    setMessage(message);
    setResponse(res);
    setAlert(true);
  };

  /* Video Call */
  /* const answerCall = () => {
    setCallAccepted(true);
    const peer = new Peer({ initiator: false, trickle: false, stream });
    peer.on("signal", (data) => {
      socket.current.emit("answerCall", { signal: data, to: call.from });
    });
    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });
    peer.signal(call.signal);
    connectionRef.current = peer;
  };
  const callUser = (id) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });
    peer.on("signal", (data) => {
      socket.current.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });
    peer.on("stream", (currentStream) => {
      userVideo.current.srcObject = currentStream;
    });
    socket.current.on("callAccepted", (signal) => {
      setCallAccepted(true);
      peer.signal(signal);
    });
    connectionRef.current = peer;
  };
  const leaveCall = () => {
    setCallEnded(true);
    connectionRef.current.destroy();
    window.location.reload();
  };
 */
  useEffect(() => {
    if (likes) {
      localStorage.setItem("likes", JSON.stringify(likes));
    }
  }, [likes]);
  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        pflegefälle,
        anfragen,
        pflegeheim,
        init,
        nachrichten,
        active,
        messages,
        chat,
        socket,
        reqStopp,
        support,
        admins,
        privateKey,
        likes,
        handleAlert,
        setAlert,
        sendKey,
        alert,
        message,
        response,
        setLikes,
        setPrivateKey,
        setReqStopp,
        setChat,
        sendMessage,
        sendAnfrage,
        sendAnfragen,
        sendSupportMessage,
        sendAdminMessage,
        setMessages,
        setUser,
        setPflegefälle,
        setNachrichten,
        setAnfragen,
        setPflegeheim,
        setActive,
        login,
        logout,
        sd,
        //Video Call
        /*         call,
        callAccepted,
        myVideo,
        userVideo,
        stream,
        name,
        setName,
        callEnded,
        me,
        callUser,
        leaveCall,
        answerCall, */
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export const useAuth = () => {
  return useContext(AuthContext);
};
